!(function (t, e) {
	"object" == typeof exports && "object" == typeof module
		? (module.exports = e())
		: "function" == typeof define && define.amd
		? define([], e)
		: "object" == typeof exports
		? (exports._vantaEffect = e())
		: (t._vantaEffect = e());
})("undefined" != typeof self ? self : this, () =>
	(() => {
		"use strict";
		var t = {
				d: (e, s) => {
					for (var i in s)
						t.o(s, i) && !t.o(e, i) && Object.defineProperty(e, i, { enumerable: !0, get: s[i] });
				},
				o: (t, e) => Object.prototype.hasOwnProperty.call(t, e),
				r: t => {
					"undefined" != typeof Symbol &&
						Symbol.toStringTag &&
						Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }),
						Object.defineProperty(t, "__esModule", { value: !0 });
				},
			},
			e = {};
		function s(t, e) {
			return null == t && (t = 0), null == e && (e = 1), t + Math.random() * (e - t);
		}
		t.r(e),
			t.d(e, { default: () => u }),
			(Number.prototype.clamp = function (t, e) {
				return Math.min(Math.max(this, t), e);
			});
		const i = t => 0.299 * t.r + 0.587 * t.g + 0.114 * t.b;
		function o(t) {
			for (; t.children && t.children.length > 0; ) o(t.children[0]), t.remove(t.children[0]);
			t.geometry && t.geometry.dispose(),
				t.material &&
					(Object.keys(t.material).forEach(e => {
						t.material[e] &&
							null !== t.material[e] &&
							"function" == typeof t.material[e].dispose &&
							t.material[e].dispose();
					}),
					t.material.dispose());
		}
		const n = "object" == typeof window;
		let h = (n && window.THREE) || {};
		n && !window.VANTA && (window.VANTA = {});
		const r = (n && window.VANTA) || {};
		(r.register = (t, e) => (r[t] = t => new e(t))), (r.version = "0.5.22");
		const a = function () {
			return (
				Array.prototype.unshift.call(arguments, "[VANTA]"), console.error.apply(this, arguments)
			);
		};
		r.VantaBase = class {
			constructor(t = {}) {
				if (!n) return !1;
				(r.current = this),
					(this.windowMouseMoveWrapper = this.windowMouseMoveWrapper.bind(this)),
					(this.windowTouchWrapper = this.windowTouchWrapper.bind(this)),
					(this.windowGyroWrapper = this.windowGyroWrapper.bind(this)),
					(this.resize = this.resize.bind(this)),
					(this.animationLoop = this.animationLoop.bind(this)),
					(this.restart = this.restart.bind(this));
				const e =
					"function" == typeof this.getDefaultOptions
						? this.getDefaultOptions()
						: this.defaultOptions;
				if (
					((this.options = Object.assign(
						{
							mouseControls: !0,
							touchControls: !0,
							gyroControls: !1,
							minHeight: 200,
							minWidth: 200,
							scale: 1,
							scaleMobile: 1,
						},
						e
					)),
					(t instanceof HTMLElement || "string" == typeof t) && (t = { el: t }),
					Object.assign(this.options, t),
					this.options.THREE && (h = this.options.THREE),
					(this.el = this.options.el),
					null == this.el)
				)
					a('Instance needs "el" param!');
				else if (!(this.options.el instanceof HTMLElement)) {
					const t = this.el;
					if (((this.el = ((s = t), document.querySelector(s))), !this.el))
						return void a("Cannot find element", t);
				}
				var s, i;
				this.prepareEl(), this.initThree(), this.setSize();
				try {
					this.init();
				} catch (t) {
					return (
						a("Init error", t),
						this.renderer &&
							this.renderer.domElement &&
							this.el.removeChild(this.renderer.domElement),
						void (
							this.options.backgroundColor &&
							(console.log("[VANTA] Falling back to backgroundColor"),
							(this.el.style.background =
								((i = this.options.backgroundColor),
								"number" == typeof i ? "#" + ("00000" + i.toString(16)).slice(-6) : i)))
						)
					);
				}
				this.initMouse(), this.resize(), this.animationLoop();
				const o = window.addEventListener;
				o("resize", this.resize),
					window.requestAnimationFrame(this.resize),
					this.options.mouseControls &&
						(o("scroll", this.windowMouseMoveWrapper), o("mousemove", this.windowMouseMoveWrapper)),
					this.options.touchControls &&
						(o("touchstart", this.windowTouchWrapper), o("touchmove", this.windowTouchWrapper)),
					this.options.gyroControls && o("deviceorientation", this.windowGyroWrapper);
			}
			setOptions(t = {}) {
				Object.assign(this.options, t), this.triggerMouseMove();
			}
			prepareEl() {
				let t, e;
				if ("undefined" != typeof Node && Node.TEXT_NODE)
					for (t = 0; t < this.el.childNodes.length; t++) {
						const e = this.el.childNodes[t];
						if (e.nodeType === Node.TEXT_NODE) {
							const t = document.createElement("span");
							(t.textContent = e.textContent), e.parentElement.insertBefore(t, e), e.remove();
						}
					}
				for (t = 0; t < this.el.children.length; t++)
					(e = this.el.children[t]),
						"static" === getComputedStyle(e).position && (e.style.position = "relative"),
						"auto" === getComputedStyle(e).zIndex && (e.style.zIndex = 1);
				"static" === getComputedStyle(this.el).position && (this.el.style.position = "relative");
			}
			applyCanvasStyles(t, e = {}) {
				Object.assign(t.style, {
					position: "absolute",
					zIndex: 0,
					top: 0,
					left: 0,
					background: "",
				}),
					Object.assign(t.style, e),
					t.classList.add("vanta-canvas");
			}
			initThree() {
				h.WebGLRenderer
					? ((this.renderer = new h.WebGLRenderer({ alpha: !0, antialias: !0 })),
					  this.el.appendChild(this.renderer.domElement),
					  this.applyCanvasStyles(this.renderer.domElement),
					  isNaN(this.options.backgroundAlpha) && (this.options.backgroundAlpha = 1),
					  (this.scene = new h.Scene()))
					: console.warn("[VANTA] No THREE defined on window");
			}
			getCanvasElement() {
				return this.renderer
					? this.renderer.domElement
					: this.p5renderer
					? this.p5renderer.canvas
					: void 0;
			}
			getCanvasRect() {
				const t = this.getCanvasElement();
				return !!t && t.getBoundingClientRect();
			}
			windowMouseMoveWrapper(t) {
				const e = this.getCanvasRect();
				if (!e) return !1;
				const s = t.clientX - e.left,
					i = t.clientY - e.top;
				s >= 0 &&
					i >= 0 &&
					s <= e.width &&
					i <= e.height &&
					((this.mouseX = s),
					(this.mouseY = i),
					this.options.mouseEase || this.triggerMouseMove(s, i));
			}
			windowTouchWrapper(t) {
				const e = this.getCanvasRect();
				if (!e) return !1;
				if (1 === t.touches.length) {
					const s = t.touches[0].clientX - e.left,
						i = t.touches[0].clientY - e.top;
					s >= 0 &&
						i >= 0 &&
						s <= e.width &&
						i <= e.height &&
						((this.mouseX = s),
						(this.mouseY = i),
						this.options.mouseEase || this.triggerMouseMove(s, i));
				}
			}
			windowGyroWrapper(t) {
				const e = this.getCanvasRect();
				if (!e) return !1;
				const s = Math.round(2 * t.alpha) - e.left,
					i = Math.round(2 * t.beta) - e.top;
				s >= 0 &&
					i >= 0 &&
					s <= e.width &&
					i <= e.height &&
					((this.mouseX = s),
					(this.mouseY = i),
					this.options.mouseEase || this.triggerMouseMove(s, i));
			}
			triggerMouseMove(t, e) {
				void 0 === t &&
					void 0 === e &&
					(this.options.mouseEase
						? ((t = this.mouseEaseX), (e = this.mouseEaseY))
						: ((t = this.mouseX), (e = this.mouseY))),
					this.uniforms &&
						((this.uniforms.iMouse.value.x = t / this.scale),
						(this.uniforms.iMouse.value.y = e / this.scale));
				const s = t / this.width,
					i = e / this.height;
				"function" == typeof this.onMouseMove && this.onMouseMove(s, i);
			}
			setSize() {
				this.scale || (this.scale = 1),
					"undefined" != typeof navigator &&
					(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
						navigator.userAgent
					) ||
						window.innerWidth < 600) &&
					this.options.scaleMobile
						? (this.scale = this.options.scaleMobile)
						: this.options.scale && (this.scale = this.options.scale),
					(this.width = Math.max(this.el.offsetWidth, this.options.minWidth)),
					(this.height = Math.max(this.el.offsetHeight, this.options.minHeight));
			}
			initMouse() {
				((!this.mouseX && !this.mouseY) ||
					(this.mouseX === this.options.minWidth / 2 &&
						this.mouseY === this.options.minHeight / 2)) &&
					((this.mouseX = this.width / 2),
					(this.mouseY = this.height / 2),
					this.triggerMouseMove(this.mouseX, this.mouseY));
			}
			resize() {
				this.setSize(),
					this.camera &&
						((this.camera.aspect = this.width / this.height),
						"function" == typeof this.camera.updateProjectionMatrix &&
							this.camera.updateProjectionMatrix()),
					this.renderer &&
						(this.renderer.setSize(this.width, this.height),
						this.renderer.setPixelRatio(window.devicePixelRatio / this.scale)),
					"function" == typeof this.onResize && this.onResize();
			}
			isOnScreen() {
				const t = this.el.offsetHeight,
					e = this.el.getBoundingClientRect(),
					s =
						window.pageYOffset ||
						(document.documentElement || document.body.parentNode || document.body).scrollTop,
					i = e.top + s;
				return i - window.innerHeight <= s && s <= i + t;
			}
			animationLoop() {
				return (
					this.t || (this.t = 0),
					(this.t += 1),
					this.t2 || (this.t2 = 0),
					(this.t2 += this.options.speed || 1),
					this.uniforms && (this.uniforms.iTime.value = 0.016667 * this.t2),
					this.options.mouseEase &&
						((this.mouseEaseX = this.mouseEaseX || this.mouseX || 0),
						(this.mouseEaseY = this.mouseEaseY || this.mouseY || 0),
						Math.abs(this.mouseEaseX - this.mouseX) + Math.abs(this.mouseEaseY - this.mouseY) >
							0.1 &&
							((this.mouseEaseX += 0.05 * (this.mouseX - this.mouseEaseX)),
							(this.mouseEaseY += 0.05 * (this.mouseY - this.mouseEaseY)),
							this.triggerMouseMove(this.mouseEaseX, this.mouseEaseY))),
					(this.isOnScreen() || this.options.forceAnimate) &&
						("function" == typeof this.onUpdate && this.onUpdate(),
						this.scene &&
							this.camera &&
							(this.renderer.render(this.scene, this.camera),
							this.renderer.setClearColor(
								this.options.backgroundColor,
								this.options.backgroundAlpha
							)),
						this.fps && this.fps.update && this.fps.update(),
						"function" == typeof this.afterRender && this.afterRender()),
					(this.req = window.requestAnimationFrame(this.animationLoop))
				);
			}
			restart() {
				if (this.scene)
					for (; this.scene.children.length; ) this.scene.remove(this.scene.children[0]);
				"function" == typeof this.onRestart && this.onRestart(), this.init();
			}
			init() {
				"function" == typeof this.onInit && this.onInit();
			}
			destroy() {
				"function" == typeof this.onDestroy && this.onDestroy();
				const t = window.removeEventListener;
				t("touchstart", this.windowTouchWrapper),
					t("touchmove", this.windowTouchWrapper),
					t("scroll", this.windowMouseMoveWrapper),
					t("mousemove", this.windowMouseMoveWrapper),
					t("deviceorientation", this.windowGyroWrapper),
					t("resize", this.resize),
					window.cancelAnimationFrame(this.req);
				const e = this.scene;
				e && e.children && o(e),
					this.renderer &&
						(this.renderer.domElement && this.el.removeChild(this.renderer.domElement),
						(this.renderer = null),
						(this.scene = null)),
					r.current === this && (r.current = null);
			}
		};
		const l = r.VantaBase,
			c = "object" == typeof window;
		let p = c && window.THREE;
		class d extends l {
			static initClass() {
				this.prototype.defaultOptions = {
					color: 16727937,
					color2: 16777215,
					size: 1,
					backgroundColor: 2299196,
					points: 10,
					maxDistance: 20,
					spacing: 15,
					showDots: !0,
					sphereRotation: 0.002,
					meshRotation: -0.004,
					xRayRotation: 0.002,
					yRayRotation: 8e-4,
					zRayRotation: 5e-4,
				};
			}
			constructor(t) {
				(p = t.THREE || p), super(t);
			}
			genPoint(t, e, s) {
				let i;
				if ((this.points || (this.points = []), this.options.showDots)) {
					const t = new p.SphereGeometry(0.25, 12, 12),
						e = new p.MeshLambertMaterial({ color: this.options.color });
					i = new p.Mesh(t, e);
				} else i = new p.Object3D();
				return (
					this.cont.add(i),
					(i.ox = t),
					(i.oy = e),
					(i.oz = s),
					i.position.set(t, e, s),
					(i.r = 0),
					this.points.push(i)
				);
			}
			onInit() {
				(this.cont = new p.Group()), this.cont.position.set(-50, -20, 0), this.scene.add(this.cont);
				let t = this.options.points,
					{ spacing: e } = this.options;
				const o = t * t * 2;
				(this.linePositions = new Float32Array(o * o * 3)),
					(this.lineColors = new Float32Array(o * o * 3));
				const n = i(new p.Color(this.options.color)),
					h = i(new p.Color(this.options.backgroundColor));
				this.blending = n > h ? "additive" : "subtractive";
				const r = new p.BufferGeometry();
				r.setAttribute(
					"position",
					new p.BufferAttribute(this.linePositions, 3).setUsage(p.DynamicDrawUsage)
				),
					r.setAttribute(
						"color",
						new p.BufferAttribute(this.lineColors, 3).setUsage(p.DynamicDrawUsage)
					),
					r.computeBoundingSphere(),
					r.setDrawRange(0, 0);
				const a = new p.LineBasicMaterial({
					vertexColors: p.VertexColors,
					blending: "additive" === this.blending ? p.AdditiveBlending : null,
					transparent: !0,
				});
				(this.linesMesh = new p.LineSegments(r, a)), this.cont.add(this.linesMesh);
				for (let s = 0; s <= t; s++)
					for (let i = 0; i <= t; i++) {
						const o = 0,
							n = (s - t / 2) * e;
						let h = (i - t / 2) * e;
						this.genPoint(n, o, h);
					}
				(this.camera = new p.PerspectiveCamera(20, this.width / this.height, 0.01, 1e4)),
					this.camera.position.set(50, 100, 150),
					this.scene.add(this.camera);
				const l = new p.AmbientLight(16777215, 0.75);
				this.scene.add(l),
					(this.spot = new p.SpotLight(16777215, 1)),
					this.spot.position.set(0, 200, 0),
					(this.spot.distance = 400),
					(this.spot.target = this.cont),
					this.scene.add(this.spot),
					(this.cont2 = new p.Group()),
					this.cont2.position.set(0, 15, 0),
					this.scene.add(this.cont2);
				const c = new p.LineBasicMaterial({ color: this.options.color2 }),
					d = [];
				for (let t = 0; t < 80; t++) {
					const t = s(18, 24),
						e = t + s(1, 6),
						i = s(-1, 1),
						o = Math.sqrt(1 - i * i),
						n = s(0, 2 * Math.PI),
						h = Math.sin(n) * o,
						r = Math.cos(n) * o;
					d.push(new p.Vector3(r * t, h * t, i * t)), d.push(new p.Vector3(r * e, h * e, i * e));
				}
				const u = new p.BufferGeometry().setFromPoints(d);
				(this.linesMesh2 = new p.LineSegments(u, c)),
					this.linesMesh2.position.set(0, 0, 0),
					this.cont2.add(this.linesMesh2);
				const m = new p.LineBasicMaterial({ color: this.options.color2, linewidth: 2 }),
					w = [];
				w.push(new p.Vector3(0, 30, 0)), w.push(new p.Vector3(0, -30, 0));
				for (let t = 0; t < 4; t++) {
					let e = 0.15 * Math.cos((t / 4) * Math.PI * 2),
						s = 0.15 * Math.sin((t / 4) * Math.PI * 2),
						i = [
							17.9, 12, 8, 5, 3, 2, 1.5, 1.1, 0.8, 0.6, 0.45, 0.3, 0.2, 0.1, 0.05, 0.03, 0.02, 0.01,
						];
					for (let t = 0; t < i.length; t++) {
						let o = i[t],
							n = 6 * (t + 1);
						w.push(new p.Vector3(e * n, o, s * n)), w.push(new p.Vector3(e * n, -o, s * n));
					}
				}
				const f = new p.BufferGeometry().setFromPoints(w);
				(this.linesMesh3 = new p.LineSegments(f, m)),
					this.linesMesh3.position.set(0, 0, 0),
					this.cont2.add(this.linesMesh3);
				const g = new p.LineBasicMaterial({ color: this.options.color }),
					y = new p.SphereGeometry(18 * this.options.size, 18, 14),
					M = new p.EdgesGeometry(y);
				(this.sphere = new p.LineSegments(M, g)),
					this.sphere.position.set(0, 0, 0),
					this.cont2.add(this.sphere),
					(this.cont2.rotation.x = -0.25);
			}
			onUpdate() {
				let t;
				null != this.helper && this.helper.update(),
					null != this.controls && this.controls.update();
				const e = this.camera;
				Math.abs(e.tx - e.position.x) > 0.01 &&
					((t = e.tx - e.position.x), (e.position.x += 0.02 * t)),
					Math.abs(e.ty - e.position.y) > 0.01 &&
						((t = e.ty - e.position.y), (e.position.y += 0.02 * t)),
					c && window.innerWidth < 480
						? e.lookAt(new p.Vector3(-10, 0, 0))
						: c && window.innerWidth < 720
						? e.lookAt(new p.Vector3(-20, 0, 0))
						: e.lookAt(new p.Vector3(-40, 0, 0));
				let s = 0,
					i = 0,
					o = 0;
				const n = new p.Color(this.options.backgroundColor),
					h = new p.Color(this.options.color),
					r = new p.Color(this.options.color2),
					a = h.clone().sub(n);
				this.rayCaster &&
					this.rayCaster.setFromCamera(new p.Vector2(this.rcMouseX, this.rcMouseY), this.camera),
					this.linesMesh2 &&
						((this.linesMesh2.rotation.x += this.options.xRayRotation),
						(this.linesMesh2.rotation.y += this.options.yRayRotation),
						(this.linesMesh2.rotation.z += this.options.zRayRotation)),
					this.sphere &&
						((this.sphere.rotation.y += this.options.sphereRotation),
						(this.linesMesh3.rotation.y += this.options.meshRotation));
				for (let t = 0; t < this.points.length; t++) {
					let e, r;
					const l = this.points[t];
					r = this.rayCaster ? this.rayCaster.ray.distanceToPoint(l.position) : 1e3;
					const c = r.clamp(5, 15);
					(l.scale.z = (0.25 * (15 - c)).clamp(1, 100)),
						(l.scale.x = l.scale.y = l.scale.z),
						(l.position.y =
							2 * Math.sin(l.position.x / 10 + 0.01 * this.t + (l.position.z / 10) * 0.5));
					for (let r = t; r < this.points.length; r++) {
						const t = this.points[r],
							c = l.position.x - t.position.x,
							d = l.position.y - t.position.y,
							u = l.position.z - t.position.z;
						if (((e = Math.sqrt(c * c + d * d + u * u)), e < this.options.maxDistance)) {
							let r,
								c = 2 * (1 - e / this.options.maxDistance);
							(c = c.clamp(0, 1)),
								(r =
									"additive" === this.blending ? new p.Color(0).lerp(a, c) : n.clone().lerp(h, c)),
								(this.linePositions[s++] = l.position.x),
								(this.linePositions[s++] = l.position.y),
								(this.linePositions[s++] = l.position.z),
								(this.linePositions[s++] = t.position.x),
								(this.linePositions[s++] = t.position.y),
								(this.linePositions[s++] = t.position.z),
								(this.lineColors[i++] = r.r),
								(this.lineColors[i++] = r.g),
								(this.lineColors[i++] = r.b),
								(this.lineColors[i++] = r.r),
								(this.lineColors[i++] = r.g),
								(this.lineColors[i++] = r.b),
								o++;
						}
					}
				}
				return (
					this.linesMesh.geometry.setDrawRange(0, 2 * o),
					(this.linesMesh.geometry.attributes.position.needsUpdate = !0),
					(this.linesMesh.geometry.attributes.color.needsUpdate = !0),
					this.sphere.material.color.set(h),
					this.linesMesh2.material.color.set(r),
					this.linesMesh3.material.color.set(r),
					0.001 * this.t
				);
			}
			onMouseMove(t, e) {
				const s = this.camera;
				s.oy || ((s.oy = s.position.y), (s.ox = s.position.x), (s.oz = s.position.z));
				const i = Math.atan2(s.oz, s.ox),
					o = Math.sqrt(s.oz * s.oz + s.ox * s.ox),
					n = i + 1.5 * (t - 0.5) * (this.options.mouseCoeffX || 1);
				(s.tz = o * Math.sin(n)),
					(s.tx = o * Math.cos(n)),
					(s.ty = s.oy + 80 * (e - 0.5) * (this.options.mouseCoeffY || 1)),
					this.rayCaster,
					(this.rcMouseX = 2 * t - 1),
					(this.rcMouseY = 2 * -t + 1);
			}
			onRestart() {
				this.scene.remove(this.linesMesh), (this.points = []);
			}
		}
		d.initClass();
		const u = r.register("GLOBE", d);
		return e;
	})()
);
