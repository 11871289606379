
			VANTA.GLOBE({
				el: "body",
				mouseControls: false,
				touchControls: false,
				gyroControls: false,
				minHeight: 200.0,
				minWidth: 200.0,
				scale: 1.0,
				scaleMobile: 1.0,
				color: 0x3fd1ff,
				color2: 0xffffff,
				backgroundColor: 0x0,
				size: 1.5,
				sphereRotation: -0.002,
				meshRotation: 0.004,
			});
		